products_cache = require '../products_caching.coffee'

$ = require 'jquery'
Q = require 'q'

store = {}
store.items = []
store.cache = {}

settings = {
  product_url: "/api/products/ID.json?branch_id=#{App.branch_id}"
  distribution_product_url: "/api/products/ID.json?distribution=1&branch_id=#{App.branch_id}"
}

store.fetchItem = (id, options = {})->
  console.log "-- PRODUCTS_STORE:: fetchItem => FETCHING PRODUCT..."

  deferred = Q.defer()

  unless id
    deferred.reject({})
    return deferred.promise

  item = @fetchItemFromCache(id)

  if item
    deferred.resolve(item)
  else
    url = productsURL(id, options)
    @fetchItemFromAPI(id, url, deferred)

  deferred.promise


store.fetchItemFromCache = (id)->
  @cache[id]

store.cacheItem = (id, data)->
  @cache[id] = data

store.fetchItemFromAPI = (id, url, deferred)->
  $.get(url)
    .done((response)=>
      console.log "-- PRODUCTS_STORE:: fetchItem => Fetched PRODUCT (#{id})"
      @cacheItem(id, response)
      deferred.resolve(response)

  ).fail(->
    console.error "-- PRODUCTS_STORE:: fetchItem => CAN NOT FETCH PRODUCT"
  )

store.fetchSimpleItems = (component)->
  console.log "-- PRODUCTS_CACHE:: fetchSimpleItems => FETCHING ALL ITEMS!..."
  component.is_ready = true
  products_cache.loadProducts().then(()=>
    console.log('Store::fetchSimpleItems => Products Loaded')
    component.is_ready = true
  )

productsURL = (id, options)->
  url = if options['distribution']
    settings.distribution_product_url
  else
    settings.product_url

  url.replace('ID', id)


module.exports = store
