/* eslint no-console:0 */

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../stylesheets/app.scss"
import "@yaireo/tagify/dist/tagify.css"

// disable products cache until we need it
// import products_cache from "../javascripts/products_caching.coffee"
// products_cache.flushAndLoadExpiredItems()

// import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
// Turbo.session.drive = false

Turbo.config.forms.confirm = async (message, element) => {
  // console.log(message, element)
  let result = await sweetAlertConfirmBox(message);

  return new Promise((resolve, reject) => {
    resolve(result)
  })
};
// Turbo.setConfirmMethod();


import { StreamActions } from "@hotwired/turbo"

StreamActions.close_sidebar = function() {
  console.log("close sidebar")
  const sidebarEvent = new CustomEvent("sidebar:close");
  document.dispatchEvent(sidebarEvent); // Dispatch the event globally
}


// import * as ActionCable from '@rails/actioncable'
// ActionCable.logger.enabled = true

import "../javascripts/config"
import "../javascripts/utils"
import "../javascripts/plugins-js"
import "../javascripts/notification-helpers"

import "trix"
import "@rails/actiontext"


require("channels")

import "controllers"

import "javascripts/custom-elements/pie-chart"

import "chartkick/chart.js"

import "javascripts/sidebar-details.coffee"
import "javascripts/chatrooms.coffee"
import "../javascripts/ui_handlers.coffee"
import "../javascripts/custom_confirmation"
import "../javascripts/am-charts"
import "../javascripts/plugins.coffee"

// import '../javascripts/mithril/global_search_module'
// import '../javascripts/mithril/product_categories_handling_module'


import 'javascripts/vue/mixins/common_methods.coffee'
import 'javascripts/vue/directives/common.coffee'

import 'javascripts/vue/mrp/bill_of_material.coffee'
import 'javascripts/vue/mrp/routing.coffee'
import 'javascripts/vue/mrp/production_order.coffee'
import "javascripts/vue/mrp/mrp_planning"

import 'javascripts/vue/accounting/record-view.coffee'
import 'javascripts/vue/accounting/budget-view.coffee'

import 'javascripts/vue/inventory/stock-adjustment-view.coffee'
import 'javascripts/vue/inventory/stock-transfer-view.coffee'
import 'javascripts/vue/inventory/location-stock-transfer-view.coffee'
import 'javascripts/vue/inventory/stocktaking-view.coffee'

import 'javascripts/vue/sales_order/sales-order-view.coffee'
import 'javascripts/vue/sales_order/assign-stores-view.coffee'
import 'javascripts/vue/sales_order/sales-order-invoice-view.coffee'
import 'javascripts/vue/sales_order/sales-order-fulfillment-view.coffee'
import 'javascripts/vue/sales_order/sales-order-return-view.coffee'

import 'javascripts/vue/sales_order/sales-order-reservation.coffee'
import 'javascripts/vue/general-sales-return-view.coffee'
import 'javascripts/vue/pos/pos-ui-view.coffee'
import 'javascripts/vue/pos/return_order/return-order.coffee'

import 'javascripts/vue/purchase_order/purchase-order-view.coffee'
import 'javascripts/vue/purchase_order/purchase-order-procurement-view.coffee'
import 'javascripts/vue/purchase_order/purchase-order-return-view.coffee'

import 'javascripts/vue/purchase_order/purchase-order-request-view.coffee'
import 'javascripts/vue/general-purchase-return-view.coffee'
import 'javascripts/vue/general-purchase-return-fulfillment-view.coffee'

import 'javascripts/vue/product_selling_price_manager.coffee'
import 'javascripts/vue/low-quantity-report-filters.coffee'

import 'javascripts/vue/fi_control/debit-memo-view.coffee'
import 'javascripts/vue/fi_control/credit-memo-view.coffee'
import 'javascripts/vue/fi_control/services-invoice.coffee'
import 'javascripts/vue/fi_control/taxable-purchase-invoice-view.coffee'
import 'javascripts/vue/fi_control/taxable-return-purchase-invoice-view.coffee'

import 'javascripts/vue/fi_control/debit-reconciliation-view.coffee'
import 'javascripts/vue/fi_control/credit-reconciliation-view.coffee'

import 'javascripts/vue/product-cost-equation-view.coffee'

import 'javascripts/vue/distribution/entity-stock-transfer-view.coffee'

import 'javascripts/vue/notifications-component.coffee'
import 'javascripts/vue/product-price-edits-component.coffee'

import 'javascripts/vue/hr/application-form.coffee'
import 'javascripts/vue/hr/onboarding-task-list.coffee'
import 'javascripts/vue/hr/offboarding-task-list.coffee'

import 'javascripts/vue/contract/contract-view.coffee'
import 'javascripts/vue/project/clearance-view.coffee'

import 'javascripts/vue/hr/conflict/incident-view.coffee'
