import { Controller } from "@hotwired/stimulus"
import { debounce } from "javascripts/helpers"
import $ from "jquery";

export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 300
    }
  }

  connect () {
    if (this.delayValue) {
      this.submit = debounce(this.submit.bind(this), this.delayValue)
    }
  }

  submit(event) {
    this.element.requestSubmit()
  }
}
