import swal from 'sweetalert'
import { Turbo } from "@hotwired/turbo-rails"

window.sweetAlertConfirmBox = (message, params = {}) => {
  let options = {
    title: message,
    text: params.text,
    icon: "warning",
    closeOnEsc: false,
    buttons: {
      cancel: {
        text: "لا",
        value: null,
        visible: true,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "نعم بالتأكيد!",
        value: true,
        visible: true,
        className: "",
        closeModal: true
      }
    }
  };

  return swal(options);
}
//
// Turbo.config.forms.confirm = async (message, element) => {
//   // console.log(message, element)
//   let result = await sweetAlertConfirmBox(message);
//
//   return new Promise((resolve, reject) => {
//     resolve(result)
//   })
// };
// // Turbo.setConfirmMethod();

$.rails.allowAction = (element) => {
  const message = element.data("confirm")
  if (!message) {
    return true;
  }

  let answer = false
  let callback = undefined

  if ($.rails.fire(element, "confirm")) {
    sweetAlertConfirmBox(message)
      .then((value) => {
        callback = $.rails.fire(element, "confirm:complete", [answer])
        if (value && callback) {
          let oldAllowAction = $.rails.allowAction
          $.rails.allowAction = () => true

          element.trigger("click")
          $.rails.allowAction = oldAllowAction
        }
      });
  }

  return false
}
