$ = require 'jquery'
require 'semantic-ui-css/components/dropdown.js'

window.jQuery = $
ENTER_KEY = 13

greenPOS = window.greenPOS or {}

$empty_option_field = $('<option>', {value: '', text: ''})

greenPOS.bind_payment_methods_handler = ()->
  toggle_dropdowns = (method)->
    $('.credit-cards-wrapper').addClass('js-hidden')
    $('.bank-accounts-wrapper').addClass('js-hidden')

    if method is 'credit_card'
      $('.credit-cards-wrapper').removeClass('js-hidden')

    if method is 'bank_transfer'
      $('.bank-accounts-wrapper').removeClass('js-hidden')

  toggle_dropdowns($('.payment-methods-wrapper select').val())

  $('.payment-methods-wrapper select').on 'change', (e)->
    $('.credit-cards-wrapper select').dropdown('restore defaults')
    $('.bank-accounts-wrapper select').dropdown('restore defaults')

    toggle_dropdowns(e.currentTarget.value)



$ ->
  click_full_row()
  prevent_enter_in_barcode_field()

  trigger_select_form($('.js-product-cat-filter-dropdown'))
  trigger_select_form($('.js-product-status-filter-dropdown'))


trigger_select_form = ($el)=>
#  $el.dropdown({
#    onChange: (value, text, $selectedItem)->
#      prev_value = $el.data('selected')
#      $el.data('selected', value)
#
#      if prev_value isnt value
#        $selectedItem.parents('form').submit()
#  })

prevent_enter_in_barcode_field = ()->
  $product_ean_code = document.querySelector('#product_ean_code')
  return unless $product_ean_code

  $product_ean_code.addEventListener 'keydown', (e)->
    if e.which is ENTER_KEY
      e.preventDefault()
      return false


click_full_row = ()->
  document.addEventListener('click', (e) ->
    target = e.target;

    if target.matches('.user-locations-list > .item') or target.matches('.user-stores-list > .item')
      input = target.querySelector('input')
      input.checked = !input.checked
  )


greenPOS.bind_appraisal_context_types_handler = ()->
  $('.select_appraisal_context select').on 'change', (e)->
    context_type = e.currentTarget.value
    $.ajax
      url: '/hr/appraisal/forms/get_context_items'
      method: 'GET'
      data:
        context_type: context_type
      dataType: 'json'
      success: (response) ->
        $context_item_select = $('#hr_appraisal_form_context_id')
        $context_item_select.empty().append $empty_option_field
        $.each response.context_items, (index, item) ->
          $context_item_select.append $('<option>',
            value: item[0]
            text: item[1]
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText

greenPOS.bind_appraisal_category_types_handler = ()->
  $('.select_appraisal_category select').on 'change', (e)->
    category = e.currentTarget.value
    $.ajax
      url: '/hr/appraisal/category_types/get_subcategories'
      method: 'GET'
      data:
        category: category
      dataType: 'json'
      success: (response) ->
        $subcategorySelect = $('#hr_appraisal_form_category_type_id')
        $subcategorySelect.empty().append $empty_option_field
        $.each response.category_types, (index, subcategory) ->
          $subcategorySelect.append $('<option>',
            value: subcategory[0]
            text: subcategory[1]
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText

greenPOS.bind_kpi_context_types_handler = ()->
  $('.select_kpi_context select').on 'change', (e)->
    context_type = e.currentTarget.value
    $.ajax
      url: '/hr/appraisal/forms/get_context_items'
      method: 'GET'
      data:
        context_type: context_type
      dataType: 'json'
      success: (response) ->
        $('#hr_kpi_form_context_id').empty()
        $context_item_select = $('#hr_kpi_form_context_id')
        $context_item_select.empty().append $empty_option_field
        $.each response.context_items, (index, item) ->
          $('#hr_kpi_form_context_id').append $('<option>',
            value: item[0]
            text: item[1]
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText

greenPOS.bind_kpi_items_handler = ()->
  $('.select_kpi select').on 'change', (e)->
    kpi = e.currentTarget.value
    kpi_input_id = e.currentTarget.id
    kpi_item_input_id = kpi_input_id.replace(/kpi(?!.*kpi)/, "kpi_item_id")
    $.ajax
      url: '/hr/kpi/forms/get_kpi_items'
      method: 'GET'
      data:
        kpi: kpi
      dataType: 'json'
      success: (response) ->
        $("#" + kpi_item_input_id).empty()
        $kpi_item_select = $("#" + kpi_item_input_id)
        $kpi_item_select.empty().append $empty_option_field
        $.each response.kpi_items, (index, item) ->
          $("#" + kpi_item_input_id).append $('<option>',
            value: item[0]
            text: item[1]
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText

greenPOS.bind_toggle_infraction_items_handler = ()->
  $('.infraction-checkbox').on 'change', (e)->
    target = e.target;
    console.log(target.checked)
    listItem = target.closest('.infraction-item');
    deleteField = listItem.querySelector('.delete-field');
    if (!target.checked)
      deleteField.value = true;
    else
      deleteField.value = false;

greenPOS.bind_employee_infractions_handler = ()->
  $('.select_employee select').on 'change', (e)->
    employee = e.currentTarget.value
    $.ajax
      url: '/hr/infraction/employees/get_infractions'
      method: 'GET'
      data:
        employee: employee
      dataType: 'json'
      success: (response) ->
        $infraction_item_select = $('#hr_infraction_employee_infraction_type_id')
        $infraction_item_select.empty().append $empty_option_field
        $.each response.infractions, (index, item) ->
          $infraction_item_select.append $('<option>',
            value: item.id
            text: item.name
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText


greenPOS.bind_infraction_value_handler = ()->
  $('#hr_infraction_employee_infraction_type_id').on 'change', (e)->
    infraction_id = e.currentTarget.value
    employee_id = $('#hr_infraction_employee_employee_id').val();
    $.ajax
      url: '/hr/infraction/employees/get_infraction_value'
      method: 'GET'
      data:
        infraction: infraction_id,
        employee: employee_id
      dataType: 'json'
      success: (response) ->
        $value_field = $('#hr_infraction_employee_value')
        $value_field.empty()
        $value_field.val(response.value)
      error: (xhr, status, error) ->
        console.error xhr.responseText

greenPOS.bind_area_locations_handler = ()->
  $('.select_area select').on 'change', (e)->
    area_id = e.currentTarget.value
    $.ajax
      url: '/pms/areas/area_locations'
      method: 'GET'
      data:
        id: area_id
      dataType: 'json'
      success: (response) ->
        $location_select = $('#pms_location')
        $location_select.empty().append $empty_option_field
        $point_select = $('#pms_point')
        $point_select.empty().append $empty_option_field
        $.each response.locations, (index, item) ->
          $location_select.append $('<option>',
            value: item[0]
            text: item[1]
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText

greenPOS.bind_location_points_handler = ()->
  $('#pms_location').on 'change', (e)->
    location_id = e.currentTarget.value
    $.ajax
      url: '/pms/locations/location_points'
      method: 'GET'
      data:
        id: location_id
      dataType: 'json'
      success: (response) ->
        $('#pms_point').empty()
        $point_select = $('#pms_point')
        $point_select.empty().append $empty_option_field
        $.each response.points, (index, item) ->
          $('#pms_point').append $('<option>',
            value: item[0]
            text: item[1]
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText 

greenPOS.bind_reschedule_type_handler = ()->
  $('#hr_payments_loan_reschedule_reschedule_type').on 'change', (e)->
    reschedule_type = e.currentTarget.value

    hideAll = ->
      $('.from-date, .to-date, .installment-amount').addClass('js-hidden')

    showOnly = (element) ->
      hideAll()
      $(element).removeClass('js-hidden')

    if reschedule_type == "edit_monthly_amount"
      showOnly('.installment-amount')
    else if reschedule_type == "exclude_month"
      showOnly('.from-date')
    else if reschedule_type == "postpone"
      showOnly('.from-date, .to-date')
    else
      hideAll()

greenPOS.bind_employee_projects_handler = ()->
  $('#hr_timesheet_clock_employee_id').on 'change', (e)->
    employee_id = e.currentTarget.value
    $.ajax
      url: '/hr/employees/employee_projects'
      method: 'GET'
      data:
        id: employee_id
      dataType: 'json'
      success: (response) ->
        $point_select = $('#hr_timesheet_clock_point_id')
        $point_select.empty().append $empty_option_field
        $.each response.projects, (index, item) ->
          $point_select.append $('<option>',
            value: item[0]
            text: item[1]
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText 

greenPOS.bind_employee_points_handler = (src_selector, target_selector)->
  src_selector.on 'change', (e)->
    employee_id = e.currentTarget.value
    $.ajax
      url: '/hr/employees/employee_points'
      method: 'GET'
      data:
        id: employee_id
      dataType: 'json'
      success: (response) ->
        $point_select = target_selector
        selected_value = parseFloat($point_select.val())

        $point_select.empty()#.append $empty_option_field

        $.each response.points, (index, item) ->
          $point_select.append $('<option>',
            value: item[0]
            text: item[1]
            selected: if item[0] is selected_value then 'selected' else ''
          )
      error: (xhr, status, error) ->
        console.error xhr.responseText

greenPOS.bind_project_points_handler = (src_selector, target_selector)->
  src_selector.on 'change', (e)->
    project_id = e.currentTarget.value
    url = "/pms/projects/#{project_id}/points.json"

    fetch(url, {credentials: 'same-origin'})
      .then((response) => response.json())
      .then (data) =>
        selectBox = target_selector;
        selectBox.empty()
        data.forEach (item) =>
          opt = document.createElement('option')
          opt.value = item.id
          opt.innerHTML = item.name
          opt.selected = parseInt(selectBox.val()) is item.id
          selectBox.append(opt)
      .catch((error) => console.error(error))

greenPOS.bind_employee_project_assignees_handler = (src_selector, target_selector)->
  src_selector.on 'change', (e)->
    employee_id = e.currentTarget.value
    url = "/hr/employees/#{employee_id}/project_assignees.json"

    selectBox = target_selector;
    selectBox.empty()

    fetch(url, {credentials: 'same-origin'})
      .then((response) => response.json())
      .then (data) =>
        data.forEach (item) =>
          opt = document.createElement('option')
          opt.value = item.id
          opt.innerHTML = item.name
          opt.selected = parseInt(selectBox.val()) is item.id
          selectBox.append(opt)
      .catch((error) => console.error(error))
