import { Controller } from "@hotwired/stimulus"
import { debounce } from "javascripts/helpers"
import $ from "jquery";

export default class extends Controller {
  static targets = ["submitButton"];

  connect () {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300)
  }

  submitButtonTargetConnected() {
    this.bindSubmit();
    this.trigger_form_events();
  }

  bindSubmit() {
    this.submitButtonTarget.addEventListener("click", this.submit.bind(this));
  }

  submit(event) {
    if (this.form) this.form.requestSubmit()
  }

  debouncedSubmit(event) {
    this.submit(event)
  }

  get form() {
    return this.element.querySelector("form");
  }

  trigger_form_events() {
    if (!this.form) return;

    $(this.form).on('ajax:before', function (request) {
      greenPOS.ajaxEventHelpers.before_event();
    });
    $(this.form).on('ajax:complete.rails', function (request) {
      greenPOS.ajaxEventHelpers.complete_event();
    });
  }
}
