import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle(event) {
    // Ensure the click is only on the first cell
    if (event.target.cellIndex === 0) {
      event.target.parentElement.classList.toggle("selected");
    }
  }
}
