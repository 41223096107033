import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  static values = {
    remoteUrl: String
  };

  connect () {
    this.bind_dropdown();
  }

  initialize() {
    this.dropdown = null;
  }

  bind_dropdown() {
    let settings =  {
      fullTextSearch: true,
      showOnFocus: true,
      forceSelection: false,
      placeholder: this.placeholder,
      match: 'text',
      clearable: this.clearable,
      // transition: 'scale'
    };

    let remoteSettings = {
      fullTextSearch: false,
      throttle: 500,
      apiSettings: {
        cache: false,
        throttle: 500,
        url: this.url
      },
      minCharacters: 3,
      saveRemoteData: false,
    };

    if (this.hasRemoteUrlValue) {
      settings = {...settings, ...remoteSettings};
    }

    // console.log("SETTINGS", settings);
    // console.log("URL", this.url);

    this.dropdown = $(this.element).dropdown(settings);
  }

  get url() {
    let url = this.remoteUrlValue.replace('QUERY', '{query}');
    return url;
  }

  get placeholder() {
    return this.element.classList.contains('use-placeholder') ? 'auto' : false
  }

  get clearable() {
    return this.element.classList.contains('clearable')
  }

  disconnect() {
  }
};
