import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.init_event_listeners();
  }

  open() {
    console.log("Search dialog opened!");
    this.element.showModal()
  }

  close() {
    this.element.close()
  }

  init_event_listeners() {
    this.element.addEventListener('hotkey-fire', event => {
      // ensure the default `focus()`/`click()` is prevented:
      event.preventDefault();
      this.open();
    });

    this.element.addEventListener('mousedown', event => {
      if (event.target === event.currentTarget) {
        event.currentTarget.close()
      }
    });
  }

  disconnect() {
    this.element.removeEventListener('hotkey-fire', event => {
      event.preventDefault();
      this.open();
    });

    this.element.removeEventListener('mousedown', event => {
      if (event.target === event.currentTarget) {
        event.currentTarget.close()
      }
    });

    this.close();
  }

};
