$ = require 'jquery'
Vue = require 'vue'
_ = require 'lodash/core'
store = require '../stores/default.coffee'
products_store = require '../products_store.coffee'
eventHub = require '../event_hub.coffee'
formSubmitMixin = require '../mixins/form_submit.coffee'
require '../directives/ui_dropdown.coffee'



dist_entity_stock_transfer = new Vue
  store: store
  data: ->
    items: []
    errors: []
    is_ready: false
    context: window.dist_entity_stock_transfer?.context
    store_id: window.dist_entity_stock_transfer?.store_id
    entity_id: window.dist_entity_stock_transfer?.distribution_entity_id
    entity_trip_id: window.dist_entity_stock_transfer?.distribution_entity_trip_id

  components:
    'entity-stock-transfer-item': require('./entity-stock-transfer-item.coffee')

  mixins: [formSubmitMixin]

  created: ->
    eventHub.$on('remove-item', @removeItem)
    eventHub.$on('add-item', @addItem)



  mounted: ->
    products_store.fetchSimpleItems(@).then ->
      if window.dist_entity_stock_transfer_items
        Vue.set(dist_entity_stock_transfer, 'items', window.dist_entity_stock_transfer_items)
      else
        dist_entity_stock_transfer.addItem() if dist_entity_stock_transfer
    # @$store.dispatch('LOAD_PRODUCTS')


  watch:
    items_has_duplicates: (newVal, oldVal)->
      eventHub.$emit('duplicated-items', {items: @duplicated_items})

    products_is_ready: (newVal)->
      if newVal
        if window.dist_entity_stock_transfer_items
          Vue.set(dist_entity_stock_transfer, 'items', window.dist_entity_stock_transfer_items)
        else
          dist_entity_stock_transfer.addItem() if dist_entity_stock_transfer


  computed:
    count: -> @items.length

    # products: -> @$store.state.products
    products_is_ready: -> @$store.state.products_is_ready
    # is_ready: -> @products_is_ready

    itemsWithProduct: ->
      _.filter @items, (i)-> i.product_id isnt null and not i._destroy


    itemsIsValid: ->
      vm = @
      not _.some(@itemsWithProduct, (i)-> not vm.itemIsValid(i))


    hasItems: ->
      @itemsWithProduct.length > 0


    duplicated_items: ->
      data = {}
      _.each @itemsWithProduct, (item)->
        return if item._destroy

        if data[item.product_id]
          data[item.product_id].count += 1
        else
          data[item.product_id] = {product_id: item.product_id, count: 1}
      _.filter data, (v, k)-> v.count > 1


    items_has_duplicates: ->
      @duplicated_items.length > 0


    isValidEntity: ->
      @entity_id isnt null


    isValidAttributes: ->
      @isValidEntity


    isValid: ->
      @isValidAttributes and @hasItems && @itemsIsValid and not @items_has_duplicates


  methods:
    addItem: (event)->
      item = {quantity: 1, store_id: null, product_id: null, valid: false}
      @items.push item
      event and event.preventDefault()


    removeItem: (data)->
      @items.splice(data.index, 1)


    itemIsValid: (item)->
      item.valid and item.store_id isnt null


    selectContent: (e)->
      Vue.nextTick => e.target.select()



if document.querySelector('.dist-entity-stock-transfer-wrapper')
  dist_entity_stock_transfer.$mount('.dist-entity-stock-transfer-wrapper')

window.dist_entity_stock_transfer_view = dist_entity_stock_transfer
